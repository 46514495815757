import { Card } from '@components/ui/card'
import { LinkButton } from '@components/ui/link-button'
import routes from '@routes'
import HonorsBadgeIcon from './honors-badge-icon'
import HighHonorsBadgeIcon from './high-honors-badge-icon'
import TopBadgeIcon from './top-honors-badge-icon'
import FullWidthContainer from '@components/layout/full-width-container'

export const AnnouncementSection = () => {
  return (
    <FullWidthContainer>
      <div className="mb-[70px] small-desktop:mb-[10px]">
        <Card className="pb-[35px] flex flex-col gap-[22px] small-desktop:flex-row-reverse small-desktop:pt-[50px] small-desktop:pb-0 small-desktop:px-[40px] desktop:pt-[70px] desktop:pb-0 desktop:px-[60px] small-desktop:justify-between w-full desktop:h-[437px]">
          <div className="flex flex-wrap justify-around gap-x-5 max-w-[300px] mx-auto small-desktop:max-w-[440px] small-desktop:flex-shrink-0 small-desktop:flex-grow-0 small-desktop:basis-auto small-desktop:relative small-desktop:top-[-60px] desktop:top-[-86px] desktop:max-w-[487px]">
            <TopBadgeIcon className="small-desktop:w-[200px] small-desktop:h-[190px] desktop:w-[220px] desktop:h-[210px]" />
            <HighHonorsBadgeIcon className="small-desktop:w-[200px] small-desktop:h-[190px] desktop:w-[220px] desktop:h-[210px]" />
            <HonorsBadgeIcon className="small-desktop:w-[200px] small-desktop:h-[190px] desktop:w-[220px] desktop:h-[210px]" />
          </div>
          <div className="space-y-5 max-w-[600px] mx-auto small-desktop:max-w-[450px] small-desktop:flex-shrink small-desktop:flex-grow">
            <h2 className="text-title-md small-desktop:text-title-lg-desktop">
              We've updated our 529 Ratings!
            </h2>
            <p>
              Our 2024 529 Ratings make it easier than ever to find the plan that's right for you.
              Our new plan awards shine a light on the best performers!
            </p>
            <LinkButton
              href={routes.fiveCapRatings}
              variant="outline"
              size="lg"
              className="w-full tablet:max-w-[260px]"
            >
              See new 529 plan ratings
            </LinkButton>
          </div>
        </Card>
      </div>
    </FullWidthContainer>
  )
}
